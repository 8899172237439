<template>
  <div class="bg-bg_alt_color p-2 rounded shadow">
    <div v-if="places?.length" class="min-h-[50px]">
      <RikazGrid
        name="material"
        :items="places"
        margin="sm:ltr:-ml-3.5 sm:rtl:-mr-3.5"
      >
        <template #card="{ forKey, data }">
          <MyAccountPlacesCard
            :key="`place-grid-item-${forKey}`"
            :address="( data as AgentPlace )"
            @delete="deletePlaceLocal"
          />
        </template>
      </RikazGrid>
    </div>
    <MevcutDialouge width="auto">
      <template #activator="{ props: propsTemp }">
        <div
          class="mt-2 flex justify-center cursor-pointer items-start text-black bg-red-100 w-max mx-auto p-1"
        >
          <v-icon
            icon="mdi mdi-map-marker-circle"
            :size="15"
            class="mx-1 flex-shrink-0"
          />
          <div class="break-all text-xs" v-bind="propsTemp">
            {{ t('create_address') }}
          </div>
        </div>
      </template>
      <template #content="{ close }">
        <MyAccountPlacesCreateForm

          @created="close"
        />
      </template>
    </MevcutDialouge>
  </div>
</template>

<script setup lang="ts">
import { usePlacesStore } from '~/store/places'
import type { AgentPlace } from '~~/composables/useMenuModel'
const { t } = useI18n()
const { fetchAgentPlace, deleteAgentPlace } = usePlacesStore()
await fetchAgentPlace()
async function deletePlaceLocal(addressId: number) {
  await deleteAgentPlace(addressId)
}

const places = computed(() => {
  return usePlacesStore().places
})
</script>
