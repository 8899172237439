<template>
  <MyAccountCheckAuthLoggedIn>
    <MyAccountPlacesGrid />
  </MyAccountCheckAuthLoggedIn>
</template>

<script setup lang="ts">
const { url } = useDomainHost()

const { t } = useI18n()

useHead({
  title: t('saved_places'),
  link: [useSeoCanonical(url + '/my-account/places')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('profile'), url + '/my-account'),
      useSeoBreadcrumbItem(3, t('saved_places'), url + '/my-account/places')
    ])
  ]
})
</script>
